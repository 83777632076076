export default class ImageSlider {
    constructor(context) {
        this.els = context.querySelectorAll('[data-image-slider]');
        this.instances = [];

        if (this.els) {
            this.els.forEach(element => {
                const container = element.querySelector('.swiper-container');
                const nextEl = element.querySelector('[data-image-slider-next]');
                const prevEl = element.querySelector('[data-image-slider-prev]');
                this.instances.push(new Swiper(container, {
                    // Navigation arrows
                    navigation: {
                        nextEl: nextEl,
                        prevEl: prevEl,
                    },
                }));
            });
        }
    }
    dispose() {
        this.els = null;
        this.instances.forEach(instance => {
            instance.destroy();
        });
    }
}