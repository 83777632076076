export default class Burger {
    constructor(context) {
        this.el = context.querySelector('.burger');
        this.body = document.querySelector('body');

        if (this.el) {
            this.el.addEventListener('click', this.onBurgerClick.bind(this));
        }
    }
    onBurgerClick(e) {
        this.body.classList.toggle('nav-chapters-open');
    }
    dispose() {
        this.el.removeEventListener('click', this.onBurgerClick.bind(this));
        this.el = null;
        this.body = null;
    }
}