import HomeNavigation from './modules/HomeNavigation';
import ImageSlider from './modules/ImageSlider';
import CustomSelect from './modules/CustomSelect';
import Popup from './modules/Popup';
import Timeline from './modules/Timeline';
import Burger from './modules/Burger';
import Preload from './modules/Preload';

/* 
 * Main init function 
 * Init the modules and the lazyload
 */
const initFunction = (context) => {
    window.HomeNavigation = new HomeNavigation(context);
    window.Timeline = new Timeline(context);
    window.Burger = new Burger(context);
    window.ImageSlider = new ImageSlider(context);
    window.CustomSelect = new CustomSelect(context);
    window.Popup = new Popup(context);

    if(!window.lazyLoadInstance) {
        window.lazyLoadInstance = new LazyLoad({
            elements_selector: ".lazy"
        });
    } else {
        window.lazyLoadInstance.update();
    }
    
    $('[data-toggle="tooltip"]').tooltip();
};

$(window).on('ajaxUpdateComplete', (e, context) => {
    Object.values(context.options.update).forEach(updateContext => {
        initFunction(document.querySelector(updateContext.replace('@', '')));
    });
});

/* BarbaJS related stuff */
const transitionElement = document.querySelector('.page-transition__background');

barba.hooks.enter(() => {
    window.scrollTo(0, 0);
    document.querySelector('body').classList.remove("nav-chapters-open");

    $('a[href*=\\#]').on('click', function (event) {
        if(this.pathname === window.location.pathname){
            document.querySelector('body').classList.remove("nav-chapters-open");
        }
    });
});
barba.init({
    prevent: ({ el }) => el.classList && el.classList.contains('prevent'),
    transitions: [{
        name: 'default-transition',
        beforeOnce(data) {
            return Preload(document);
        },
        once(data) {
            initFunction(document);
            return gsap.to(transitionElement, .5, {
                y: '100%', delay: .5
            });
        },
        leave(data) {
            return gsap.to(transitionElement, .5, {
                y: 0
            });
        },
        enter(data) {
            return Preload(data.next.container);
        },
        after(data) {
            initFunction(data.next.container);

            if(location.hash) {
                const element = document.querySelector(location.hash);
                const topElement = element.getBoundingClientRect().top + window.pageYOffset;
                window.scrollTo({
                    top: topElement, // scroll so that the element is at the top of the view
                });
            }

            return gsap.to(transitionElement, .5, {
                y: '100%', delay: .5
            });
        }
    }]
});