export default class CustomSelect {
    constructor(context) {
        this.els = context.querySelectorAll('[data-bootstrap-select]');

        if (this.els) {
            this.els.forEach(element => {
                $(element).selectpicker();
            });
        }
    }
    dispose() {
        if (this.els) {
            this.els.forEach(element => {
                $(element).selectpicker('destroy');
            });
        }
        this.els = null;
    }
}