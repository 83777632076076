import debounce from './debounce';

export default class Timeline {
    constructor(context) {
        this.context = context;
        this.timelineElem = context.querySelector('[data-timeline-progress]');
        this.timelineTitle = context.querySelector('[data-timeline-title]');
        if (this.timelineElem) {
            this.onResize();

            /* this.onScrollDebounced = debounce(this.onScroll.bind(this), 5);
            this.onResizeDebounced = debounce(this.onResize.bind(this), 5); */

            window.addEventListener('resize', this.onResize.bind(this));
            window.addEventListener('scroll', this.onScroll.bind(this));
        }
    }
    onResize(e) {
        this.chapters = [];
        this.headerHeight = this.context.querySelector('.header').clientHeight;
        this.windowHeight = window.innerHeight - this.headerHeight;
        this.bodyHeight = document.body.scrollHeight;

        document.querySelectorAll('[data-timeline-nav] a[href]').forEach((item, idx) => {
            const target = document.querySelector(item.getAttribute('href'));
            if(target) {
                const section = target.parentNode;
                const rect = section.getBoundingClientRect();
                const offset = rect.top + window.scrollY;
                this.chapters.push({
                    idx: idx,
                    navItem: item,
                    title: item.title,
                    anchorItem: target,
                    offset: offset - this.headerHeight,
                    maxOffset: offset + rect.height
                });
            }
        });

        this.chapters.forEach((elem, idx, all) => {
            elem.maxOffset = all[idx+1] ? all[idx+1]['offset'] : this.bodyHeight;
        });

        this.onScroll(e);
    }
    onScroll(e) {
        const scrollY = document.documentElement.scrollTop + document.body.scrollTop + this.windowHeight;
        const currentChapter = this.chapters.filter(elem => {
            return (scrollY >= elem.offset && scrollY <= elem.maxOffset);
        })[0];

        if(currentChapter) {
            const scrollYAdjusted = scrollY - currentChapter.offset;
            const percent = (scrollY - currentChapter.offset) / (currentChapter.maxOffset - currentChapter.offset);

            const width = ((100 / this.chapters.length) * (currentChapter.idx)) + ((100 / this.chapters.length) * percent);
            this.timelineElem.style.width = width + '%';
            this.timelineTitle.innerHTML = currentChapter.title;

            for (var i = 0; i < this.chapters.length; i++) {
                if (i <= currentChapter.idx) {
                    this.chapters[i].navItem.classList.add('is-active');
                } else {
                    this.chapters[i].navItem.classList.remove('is-active');
                }
            }
        } else {
            this.timelineElem.style.width = '0%';
            this.timelineTitle.innerHTML = this.timelineTitle.getAttribute('data-timeline-title');
            for (var i = 0; i < this.chapters.length; i++) {
                this.chapters[i].navItem.classList.remove('is-active');
            }
        }

    }
    dispose() {
        //this.el.removeEventListener('click', this.onBurgerClick.bind(this));
    }
}