// Preload images
const preloadBackgroundImages = (context) => {
    return new Promise((resolve, reject) => {
        imagesLoaded(context.querySelectorAll('.imagesloaded-bg'), { background: true }, resolve);
    });
};

const preloadImages = (context) => {
    return new Promise((resolve, reject) => {
        imagesLoaded(context.querySelectorAll('.imagesloaded'), { background: false }, resolve);
    });
};

// Preload fonts
const preloadFonts = (context) => {
    return new Promise((resolve, reject) => {
        WebFont.load({
            typekit: {
                id: 'zhp8brl'
            },
            active: resolve
        });
    });
};

// Preload Masonry (because it mess the layout)
const preloadMasonry = (context) => {
    const els = context.querySelectorAll('[data-image-masonry]');
    const instances = [];
    const promises = [];
    if (els) {
        els.forEach(element => {
            const instance = new Masonry(element, {
                columnWidth: '.masonry__sizer',
                gutter: '.masonry__gutter',
                itemSelector: '.masonry__item',
                percentPosition: true,
                isInitLayout: false
            });
            promises.push(new Promise((resolve, reject) => {
                instance.on('layoutComplete', function () {
                    resolve();
                });
            }));
            instance.layout();
        });
        return Promise.all(promises);
    }
    return null;
};

// Preload fonts and images
export default (context) => {
    return Promise.all([preloadFonts(context), preloadImages(context), preloadBackgroundImages(context), preloadMasonry(context)]);
};  