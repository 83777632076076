export default class Popup {
    constructor(context) {
        $('[data-magnific-popup]').magnificPopup({
            type: 'image',
            removalDelay: 300,
            mainClass: 'mfp-fade',
            gallery: {
                // options for gallery
                enabled: true,
                navigateByImgClick: true,
                arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"></button>',
                tPrev: 'Précédent',
                tNext: 'Suivant',
                tCounter: '<span class="mfp-counter">%curr% sur %total%</span>'
            },
            image: {
                verticalFit: true,
                titleSrc: function (item) {
                    return item.el.attr('title') + '<small>' + item.el.attr('data-description') + '</small>';
                }
            }
        });

        $('[data-magnific-popup-gallery]').each(function () {
            $(this).magnificPopup({
                type: 'image',
                delegate: 'a',
                removalDelay: 300,
                mainClass: 'mfp-fade',
                gallery: {
                    // options for gallery
                    enabled: true,
                    navigateByImgClick: true,
                    arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"></button>',
                    tPrev: 'Précédent', // title for left button
                    tNext: 'Suivant', // title for right button
                    tCounter: '<span class="mfp-counter">%curr% sur %total%</span>'
                },
                image: {
                    verticalFit: true,
                    titleSrc: function (item) {
                        return item.el.attr('title') + '<small>' + item.el.attr('data-description') + '</small>';
                    }
                },
            });
        });
    }
    dispose() {
        
    }
}